/**
=========================================================
* Material Kit 2 PRO React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import React, { useEffect, useState } from "react";

// react-router-dom components
import { Link, useNavigate } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";
import Switch from "@mui/material/Switch";
import Grid from "@mui/material/Grid";
import MuiLink from "@mui/material/Link";

// @mui icons
import FacebookIcon from "@mui/icons-material/Facebook";
import GitHubIcon from "@mui/icons-material/GitHub";
import GoogleIcon from "@mui/icons-material/Google";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import MKInput from "components/MKInput";
import MKButton from "components/MKButton";
import queryString from "query-string";

// Authentication pages components
import BasicLayout from "pages/Authentication/components/BasicLayout";

// Images
import bgImage from "assets/images/bg-sign-in-basic.jpeg";
import logoBaally from "assets/logos/logoBaally.png";
import { Button, CircularProgress, Divider } from "@mui/material";
import { HttpService } from "Api-Services/httpService";
import { DataManagementService } from "Api-Services/dataManagementService";
import Avatar from "assets/theme/components/avatar";
import MKAvatar from "components/MKAvatar";

function SignInBasic() {
  const [rememberMe, setRememberMe] = useState(false);

  const handleSetRememberMe = () => setRememberMe(!rememberMe);
  const [forceRerender, setForceRerender] = React.useState(true);

  //local constants
  const [cardAnimaton, setCardAnimation] = React.useState("cardHidden");
  const [selectedUserType, setSelectedUserType] = React.useState(1);
  const [loading, setLoading] = useState(false);
  const [isFormValid, setIsFormValid] = useState(true);
  const [invalidResponse, setInvalidResponse] = useState("");
  const [loginResponse, setLoginResponse] = useState(null);

  const [password, setPassword] = useState("");
  const [userEmail, setUserEmail] = useState("");
  const [routeSearch, setRouteSearch] = useState(null);

  const navigate = useNavigate();
  (event) => {
    setSelectedUserType(event.target.value);
  };

  useEffect(() => {
    getStart();
    // getAllUserType()
    getDocumentsAll("allOffices", "/api/test", {}); //init user offices and data
    postDocuments("posttest", "/api/posttest", { platform: "bali websites" }, {});

    let id = setTimeout(function () {
      setCardAnimation("");
    }, 700);
    // Specify how to clean up after this effect:
    return function cleanup() {
      window.clearTimeout(id);
    };
  }, []);


  useEffect(() => {
    // console.log("educationLevels", educationLevels);
    let authSearch = DataManagementService.getLocalstorageDataSecured("authSearch");
    // console.log("auth    Search", authSearch);
    
         
    // console.log("path login", path);
    // console.log(" myJobSeekerInformation", myJobSeekerInformation);
    if (location.search) {
      let locationSearch = queryString.parse(location.search);
      if (locationSearch) {
        setRouteSearch(locationSearch);
      }
    }
  }, [location.pathname, location.search]);


  const getStart = async () => {};

  const login = async () => {
    // localStorage.clear();
    let valid = false;
    setLoginResponse(null);
    setInvalidResponse("");
    setIsFormValid(true);
    if (userEmail && password) {
      setIsFormValid(true);
      valid = true;
    } else {
      setIsFormValid(false);
      valid = false;
      setInvalidResponse(" Please provide an id and password");
    }
    // console.log("userEmail&&password",userEmail,password, valid)
    if (valid) {
      setLoading(true);
      let data = {
        // "platformUserName":companyName,
        userEmail: userEmail,
        password: password,
        // "userType":selectedUserType,
        callerSite: "collegeadminportal",
      };
      let relativePath = "/api/authorization/login";
      // let AdditionalData={withCredentials: true, credentials: 'include'}//{  credentials: 'include' }//header can be included here
      await postDocuments("login", relativePath, data, {});
    }
    setLoading(false);
  };

  // const loginGoogleAuth = useGoogleLogin({
  //   onSuccess: (tokenResponse) => authGoogleResponse(tokenResponse),
  //   flow: "auth-code",
  //   redirect_uri: window.location.href.split("?")[0], //"http://localhost:3001",
  //   scope: "https://www.googleapis.com/auth/drive",
  // });

  const authGoogleResponse = async (tokenResponse) => {
    let data = {
      // "platformUserName":companyName,
      socialLoginType: "google", //"facebook"
      googleData: { grant_type: "authorization_code", code: tokenResponse.code },
      // "facebookData":{},
      callerSite: "collegeadminportal",
    };
    let relativePath = "/api/authorization/sociallogin";
    // let AdditionalData={withCredentials: true, credentials: 'include'}//{  credentials: 'include' }//header can be included here
    let socialloginResp = await postDocuments("sociallogin", relativePath, data, {});
    if (socialloginResp.success) {
      let path = "/admin/my-websites";
      window.location.assign(path);
    }
  };

  const postDocuments = async (documentName, relativePath, data) => {
    let responseD = { success: false, message: "", record: null };
    let documentsResponse = { success: false };
    setLoading(true);

    ////////// UPDATED/////////////
    documentsResponse = await HttpService.postService(relativePath, data, {}, "");
    // console.log(documentName, " data: ", documentsResponse   );
    setLoading(false);

    if (documentsResponse.success) {
      responseD.success = true;
      responseD.record = documentsResponse.data.record;
      responseD.message = documentsResponse.data.message;
      let record = responseD.record;

      //write codtions down here//

      if (documentName == "login") {
        let authSearch = DataManagementService.getLocalstorageDataSecured("authSearch");
        // let path = DataManagementService.getLocalstorageDataSecured("locationSearch");
        // localStorage.clear();
        setLoginResponse(documentsResponse);
        setLoading(true);
        // await getDocumentsAll("allOffices","/api/officeUserRole/myoffices",{})//init user offices and data
        setLoading(false);
        
        let returnToOrigin=true
         
        // console.log("authSearch ", authSearch);
        if(authSearch){
          if(authSearch.authDeepth){
            returnToOrigin=false
            // console.log("go to authSearch.authDeepth ", authSearch.authDeepth);
          if(authSearch.authDeepth=="businessaccount"){
                // console.log("go to authSearch.authDeepth=businessaccount", authSearch.authDeepth);
            // path=routeSearch.redirectTo
            // navigate("/business-login")
            // window.location.assign("/business-login");
            
          navigate("/business-login")
          }
          }
          if(returnToOrigin){ 
            // returnToOrigin=false 
              if(authSearch.redirectUrl||authSearch.redirectTo){ 
                let urlRedi=authSearch.redirectUrl||authSearch.redirectTo
                // console.log("redirectUrl", authSearch.redirectUrl);
                localStorage.clear(); 
                window.location.assign(urlRedi);
                // window.open(authSearch.redirectUrl );
              } 
          } 
        }
        // if(returnToOrigin){
        //   window.open(path);
        // }
        window.location.assign("https://baally.com");
      }
    } else {
      responseD.message = documentsResponse.message;
      if (documentName == "login") {
        setLoginResponse(responseD);
      }
    }

    // console.log(documentName, " responseD: ", responseD   );
    setForceRerender(!forceRerender);
    return responseD;
    ////////// UPDATED/////////////
  };

  const getDocumentsAll = async (documentName, relativePath, additionalData) => {
    let responseD = { success: false, message: "", record: null };
    let documentsResponse = { success: false };
    ////////// UPDATED/////////////
    documentsResponse = await HttpService.getService(
      relativePath,
      additionalData ? additionalData : {},
      ""
    );
    // //console.log(documentName, " data: ", documentsResponse   );

    if (documentsResponse.success) {
      responseD.success = true;
      responseD.record = documentsResponse.data.record;
      responseD.message = documentsResponse.data.message;
      let record = responseD.record;

      //responseD.record //write conditions down here
    }

    return responseD;
  };

  return (
    <BasicLayout image={bgImage}>
      <Card>
        <MKBox
          variant="gradient"
          bgColor="info"
          borderRadius="lg"
          coloredShadow="info"
          mx={2}
          mt={-3}
          pb={2}
          mb={1}
          textAlign="center"
        >   
          <MKBox mb={{ xs: -7, md: -7 }}  >
            <MKAvatar src={logoBaally} alt="Baally" size="xl" shadow="xl" />
          </MKBox>
          <MKTypography variant="h4" fontWeight="medium" color="white" my={1}>
              Baally
          </MKTypography>
          
        </MKBox>
        <MKBox  pb={3} px={3}
          textAlign="center">
           
        <MKBox  pb={3} px={3}
          textAlign="center">
        <MKTypography variant="h4" fontWeight="medium" mt={1}> 
            Sign in
          </MKTypography>
          {/* <Grid container spacing={3} justifyContent="center" sx={{ mt: 1, mb: 2 }}>
            <Grid item xs={2}>
              <MKTypography component={MuiLink} href="#" variant="body1" >
                <FacebookIcon color="inherit" />
              </MKTypography>
            </Grid>
            <Grid item xs={2}>
              <MKTypography component={MuiLink} href="#" variant="body1" >
                <GitHubIcon color="inherit" />
              </MKTypography>
            </Grid>
            <Grid item xs={2}>
              <MKTypography component={MuiLink} href="#" variant="body1" >
                <GoogleIcon color="inherit" />
              </MKTypography>
            </Grid>
          </Grid> */}
            <Divider sx={{ my: "0px" }} /> 
          </MKBox>
          <MKBox component="form" role="form">
            <MKBox mb={2}>
              <MKInput
                type="email"
                label="Email"
                onChange={(e) => {
                  setUserEmail(e.target.value);
                }}
                fullWidth
              />
            </MKBox>
            <MKBox mb={2}>
              <MKInput
                type="password"
                label="Password"
                onChange={(e) => {
                  setPassword(e.target.value);
                }}
                fullWidth
              />
            </MKBox>
            
            {(loginResponse || !isFormValid) && (
              <MKBox mt={3} mb={1} textAlign="center">
                {loginResponse && <small>{loginResponse.message}</small>}
                {!isFormValid && <small>{invalidResponse}</small>}
              </MKBox>
            )}
            {loading && (
              <MKBox mt={3} mb={1} textAlign="center">
                <CircularProgress size={24} />{" "}
              </MKBox>
            )}
            <MKBox mt={4} mb={1}>
              <MKButton
                disable={loading}
                variant="gradient"
                color="info"
                fullWidth
                onClick={() => {
                  login();
                }}
              >
                login
              </MKButton>
            </MKBox>
            <MKBox mt={3} textAlign="center">
              <MKTypography variant="button" color="info" component={Link}
  
              to="/forget-password"  
              fontWeight="medium"
              textGradient
              
              
              >
               <a></a> Forgot Password ?{" "}
              </MKTypography>
              {/* <Button size="small" href="https://portal.baliwebsites.bfamily.et/auth/forget-password" target="_blank">
              Forgot Password ?{" "}
              </Button> */}
            </MKBox>
            <MKBox mb={1} textAlign="center">
              <MKTypography variant="button" color="text">
                Don&apos;t have an account?{" "}
                <MKTypography
                  component={Link}
                  to="/register"
                  variant="button"
                  color="info"
                  fontWeight="medium"
                  textGradient
                >
                  REGISTER
                </MKTypography>
              </MKTypography>
            </MKBox>
          </MKBox>
        </MKBox>
      </Card>
    </BasicLayout>
  );
}

export default SignInBasic;
