/**
=========================================================
* Material Kit 2 PRO React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Card from "@mui/material/Card";
// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import MKInput from "components/MKInput";
import MKButton from "components/MKButton";
// Authentication layout components
import CoverLayout from "pages/Authentication/components/CoverLayout";

import queryString from "query-string";
// Images
import bgImage from "assets/images/bg-reset-cover.jpeg";
import { HttpService } from "Api-Services/httpService";
import React, { useEffect, useState } from "react";
import { Avatar, Box, CircularProgress, Divider, FormControl, Grid, Icon, IconButton, InputAdornment, InputLabel, LinearProgress, List, ListItem, ListItemAvatar, ListItemButton, ListItemText, OutlinedInput, Stack, TextField, Typography } from "@mui/material";
import { Delete, Folder, Person, Send, Visibility, VisibilityOff } from "@mui/icons-material";
import { Link, useParams } from "react-router-dom";

function VerifyUserCreation() {
  const [forceRerender ,setForceRerender ] =  useState(false);
  const [screenComponentIndex ,setScreenComponentIndex ] =  useState(1);
 
  const [emailVerificationCode ,setEmailVerificationCode ] =  useState("");
  const [resetSuccess ,setResetSuccess ] =  useState(false);
  const [response, setResponse] = useState(null);
  const [verificationResponse, setVerificationResponse] = useState(null);
  //local constants 
  const [usersFound, setUsersFound] = useState(null);
  const [loading, setLoading] = useState(false);
  const [companyName, setCompanyName] = useState(''); 
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [userCardId, setUserCardId] = useState('');
  const [isFormValid, setIsFormValid] = useState(false); 
  // const history=useHistory()


  const paraaa = useParams();
  useEffect(() => { 
  }, [location.pathname, location.search]);


  const postDocuments = async(documentName,relativePath,data  )=>{   
    let responseD={success:false,message:"",record: null};
    let documentsResponse={success:false}   
   
    ////////// UPDATED///////////// 
    documentsResponse= await HttpService.postService(relativePath,data,{},""); 
    // console.log(documentName, " data: ", documentsResponse   );   
  
    if(documentsResponse.data)  {
      responseD.record=documentsResponse.data.record; 
      responseD.message=documentsResponse.data.message; 
    }
    if(documentsResponse.success){  
      responseD.success=true;    
      }  
      // console.log(documentName, " responseD: ", responseD   );   
      setForceRerender(!forceRerender);
      return responseD;
    ////////// UPDATED///////////// 
  }
  
   
 const verifyUser=async ()=>{
  let resp={message : "Not found, try again",  record: null,  success :  false } 
  setResponse(null)
  setVerificationResponse(null)
  let verifyD=null;
  try {
    if (location.search) {
        let locationSearch = location.search; 
        if(locationSearch){
        verifyD = locationSearch.replace("?verify=", "");
        }
      } 
    if(verifyD){
      let data={
              "emailVerificationCode":emailVerificationCode ,
              "verify":verifyD 
            } 
        let relativePath="/api/authorization/verifyusercreation";
        setLoading(true) 
        let searchUserRes=await postDocuments("verifyToken",relativePath,data,{})
        setLoading(false) 
        if(searchUserRes){
            resp=searchUserRes
            if(!(searchUserRes.message)){ 
              resp.message="Not found, try again" 
            }
          } 
        }
        if(searchUserRes.success){
          window.location.assign("https://baally.com");
      } 
    }  
  catch (error) {
    
  } 
  setResponse(resp)
  setForceRerender(!forceRerender)  

}
 
   
  return (
    <CoverLayout coverHeight="40vh" image={bgImage}>
       <Card>
      <>
        <MKBox
          variant="gradient"
          bgColor="info"
          borderRadius="lg"
          coloredShadow="success"
          mx={2}
          mt={-3}
          py={2}
          mb={1}
          textAlign="center"
        >
          <MKTypography variant="h3" fontWeight="medium" color="white" mt={1}>
          Verify User 
          </MKTypography>
        </MKBox>
           {(!resetSuccess)&& 
        <MKBox pt={4}  px={3}>
          <MKBox component="form" role="form"> 
            <MKBox mb={4}> 
            
          <MKTypography display="block" variant="button"  mb={4}>
           Enter verification code sent on your email </MKTypography> 

              <FormControl  variant="outlined"  fullWidth >
                {/* <InputLabel htmlFor="outlined-adornment-amount">Verification Code</InputLabel> */}
                <TextField autoFocus
                    label="Verification Code"
                    id="outlined-start-adornment" 
                    onChange={(e)=>{setEmailVerificationCode(e.target.value)} }
                    InputProps={{
                      startAdornment: <InputAdornment position="start">B - </InputAdornment>,
                    }}
                  /> 
              </FormControl>
            </MKBox> 
            <MKBox mt={6} mb={1}>
              <MKButton disabled={!emailVerificationCode} variant="gradient" color="info"  
               onClick={()=>{verifyUser()}} fullWidth>
                Submit
              </MKButton>
            </MKBox>
             
          </MKBox>
        </MKBox> }
      </> 
       
      
      <MKBox  pb={3} px={3}>
          <MKBox component="form" role="form"> 
          <Divider/>
            <MKBox mt={2} mb={1}  textAlign="center">
              
             {loading&&<Box sx={{ width: '100%' }}>
                 <CircularProgress />
              </Box>}
           {(response?(response.message?true:false):false)&&<>
           <MKTypography    variant="body2"  color={response.success?"success":"warning"} my={1}>
            {response.message}
          </MKTypography>
          <Divider/>
          </>}

       


              {/* <MKButton variant="gradient" color="info"   onClick={()=>{searchUser()}} fullWidth>
                login by password
              </MKButton> */}
              <MKBox mt={3} textAlign="center">
              <MKTypography variant="button" color="text">
                Already have an account?{" "}
                <MKTypography
                  component={Link}
                  to="/login"
                  variant="button"
                  color="info"
                  fontWeight="medium"
                  textGradient
                >
                  LOGIN
                </MKTypography> 
                </MKTypography> 
                </MKBox>
              <MKBox  mb={1} textAlign="center">
              <MKTypography variant="button" color="text">
                Want to try agian?{" "}
                <MKTypography
                  component={Link}
                  to="/register"
                  variant="button"
                  color="info"
                  fontWeight="medium"
                  textGradient
                >
                  REGISTER
                </MKTypography>
              </MKTypography> 
            </MKBox>
            </MKBox>
          </MKBox>
        </MKBox>

      </Card>
    </CoverLayout>
  );
}

export default VerifyUserCreation;
