/**
=========================================================
* Material Kit 2 PRO React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Card from "@mui/material/Card";
// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import MKInput from "components/MKInput";
import MKButton from "components/MKButton";
// Authentication layout components
import CoverLayout from "pages/Authentication/components/CoverLayout";

import queryString from "query-string";
// Images
import bgImage from "assets/images/bg-reset-cover.jpeg";
import { HttpService } from "Api-Services/httpService";
import React, { useEffect, useState } from "react";
import {
  Avatar,
  Box,
  CircularProgress,
  Divider,
  FormControl,
  Grid,
  Icon,
  IconButton,
  InputAdornment,
  InputLabel,
  LinearProgress,
  List,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  OutlinedInput,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { Delete, Folder, Person, Send, Visibility, VisibilityOff } from "@mui/icons-material";
import { Link } from "react-router-dom";

function Cover() {
  const [forceRerender, setForceRerender] = useState(false);
  const [screenComponentIndex, setScreenComponentIndex] = useState(1);

  const [resetSuccess, setResetSuccess] = useState(false);
  const [response, setResponse] = useState(null);
  const [verificationResponse, setVerificationResponse] = useState(null);
  //local constants
  const [usersFound, setUsersFound] = useState(null);
  const [loading, setLoading] = useState(false);
  const [companyName, setCompanyName] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [userCardId, setUserCardId] = useState("");
  const [isFormValid, setIsFormValid] = useState(true);
  const [loginResponse, setLoginResponse] = useState(null);
  const [invalidResponse, setInvalidResponse] = useState("");
  const [passwordStrength, setPasswordStrength] = useState("");
  // const history=useHistory()

  useEffect(() => {
    if (location.search) {
      let locationSearch = queryString.parse(location.search);
      if (locationSearch) {
        verifyUserReset(locationSearch);
      }
    }
  }, [location.pathname, location.search]);

  const postDocuments = async (documentName, relativePath, data) => {
    let responseD = { success: false, message: "", record: null };
    let documentsResponse = { success: false };

    ////////// UPDATED/////////////
    documentsResponse = await HttpService.postService(relativePath, data, {}, "");
    // console.log(documentName, " data: ", documentsResponse   );

    if (documentsResponse.data) {
      responseD.record = documentsResponse.data.record;
      responseD.message = documentsResponse.data.message;
    }
    if (documentsResponse.success) {
      responseD.success = true;
    }
    // console.log(documentName, " responseD: ", responseD   );
    setForceRerender(!forceRerender);
    return responseD;
    ////////// UPDATED/////////////
  };

  const verifyUserReset = async (searchParam) => {
    // console.log("searchParam verifyUserReset",searchParam)
    setVerificationResponse(null);
    setResponse(null);
    let hashedResetToken = searchParam.hash;
    try {
      // let platformUserName=searchParam.platformUserName;
      if (hashedResetToken) {
        let data = {
          //  "platformUserName":platformUserName ,
          hashedResetToken: hashedResetToken,
        };
        // console.log("data verifyUserReset",data)
        let relativePath = "/api/authorization/verifyPasswordResetToken";
        setLoading(true);
        let searchUserRes = await postDocuments("verifyToken", relativePath, data, {});
        // console.log("res verifyUserReset",searchUserRes)
        setLoading(false);

        // console.log("searchUserRes",searchUserRes)
        if (searchUserRes.success) {
          // setResponse(searchUserRes);
          setVerificationResponse(searchUserRes.record);
          // setUsersFound(searchUserRes)
          setScreenComponentIndex(0);
        } else { 
             if (!searchUserRes.message) {
              setResponse({ message: "Not found, try again", record: null, success: false });
            }
            else{
              setResponse({ message: searchUserRes.message, record: null, success: false });
            }
        }
        setForceRerender(!forceRerender);
      }
    } catch (error) {}
  };

  const validateForm = (passwrd) => {
    let valid = true;
    let message = "";
    setLoginResponse(null); 
    setInvalidResponse("");
    setIsFormValid(true);  
      if (passwrd) { 
          if (/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*(\W|_)).{5,}$/.test(passwrd)) {
            setPasswordStrength("strong")
          } 
          else if(/^(?=.*[a-z])(?=.*\d).{5,}$/.test(passwrd)){
            setPasswordStrength("middle")
          }
          else{
            valid = false;
            setPasswordStrength("weak")
            message = `Password is weak , put at least 8 characters combination of letter, number and special character, `;
          } 
      } else {
        valid = false;
        setPasswordStrength("weak")
        message = "Fill all the inputs correctly!";
      } 
    setInvalidResponse(message);
    setIsFormValid(valid);
    return valid;
  };
 
  const resetPassword = async () => {
    setResponse(null);
    setResetSuccess(false);
    try {
      let validD = false;
      if (newPassword) {
        validD = true;
      } 
      setLoading(true);
      // console.log("resetPassword",(newPassword==confirmPassword),newPassword,confirmPassword,validD, verificationResponse.hashedResetToken)

      if (validD && verificationResponse?.hashedResetToken) {
        let data = {
          hashedResetToken: verificationResponse?.hashedResetToken,
          // "platformUserName": platformUserName,
          password: newPassword,
          // "confirmPassword": confirmPassword
        };
        let relativePath = "/api/authorization/resetuserpassword";
        let resetPasswordRes = await postDocuments("resetPassword", relativePath, data, {});
        setResponse(resetPasswordRes);
        if (resetPasswordRes?.success) {
          setResetSuccess(resetPasswordRes.success);
        } 
      }
    } catch (error) {
      // console.log(error,"ererer")
    }
      setLoading(false);
  };


  return (
    <CoverLayout coverHeight="50vh" image={bgImage}>
      <Card>
        {screenComponentIndex == 0 && (
          <>
            <MKBox
              variant="gradient"
              bgColor="info"
              borderRadius="lg"
              coloredShadow="success"
              mx={2}
              mt={-3}
              py={2}
              mb={1}
              textAlign="center"
            >
              <MKTypography variant="h3" fontWeight="medium" color="white" mt={1}>
                Reset Password
              </MKTypography>
              {!resetSuccess && (
                <MKTypography display="block" variant="button" color="white" my={1}>
                  Enter your new password and confirm
                </MKTypography>
              )}
            </MKBox>
            {!resetSuccess && (
              <MKBox pt={4} px={3}>
                <MKBox component="form" role="form">
                  <MKBox mb={4}>
                    <FormControl variant="outlined" fullWidth>
                      <InputLabel htmlFor="outlined-adornment-password">New Password</InputLabel>
                      <OutlinedInput
                        fullWidth
                        variant="outlined"
                        id="input-with-icon-textfield"
                        label="New Password"
                        error={
                          !(
                            isFormValid ||
                            (confirmPassword ? confirmPassword != newPassword : false)
                          )
                        }
                        onChange={(e) => {
                          setNewPassword(e.target.value);
                          validateForm(e.target.value)
                        }}
                        type={showPassword ? "text" : "password"}
                        endAdornment={
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={() => {
                                setShowPassword(!showPassword);
                              }}
                              // onMouseDown={handleMouseDownPassword}
                              edge="end"
                            >
                              {showPassword ? <VisibilityOff /> : <Visibility />}
                            </IconButton>
                          </InputAdornment>
                        }
                      />
                    </FormControl>
                  </MKBox>
                  {/* <MKBox mb={4}>
                    <FormControl variant="outlined" fullWidth>
                      <InputLabel htmlFor="outlined-adornment-password">
                        Confirm Password
                      </InputLabel>
                      <OutlinedInput
                        fullWidth
                        variant="outlined"
                        id="input-with-icon-textfield"
                        label="Confirm Password"
                        // error={newPassword?((newPassword==confirmPassword)?false:true):true}
                        error={
                          !(isFormValid || (newPassword ? newPassword != confirmPassword : false))
                        }
                        onChange={(e) => {
                          setConfirmPassword(e.target.value);
                        }}
                        type={showConfirmPassword ? "text" : "password"}
                        endAdornment={
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={() => {
                                setShowConfirmPassword(!showConfirmPassword);
                              }}
                              // onMouseDown={handleMouseDownPassword}
                              edge="end"
                            >
                              {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                            </IconButton>
                          </InputAdornment>
                        }
                      />
                    </FormControl>
                  </MKBox> */} 
                {(newPassword&&passwordStrength)?(
                  <MKBox  >
                      <Typography 
                              sx={{ display: 'inline' }}
                              component="span"
                        variant="body2"
                      >
                        {" Password Strength: "} 
                        <Typography  
                sx={{ display: 'inline' }}
                component="span" variant="h6"
                        color={(passwordStrength=="strong") ? "green" :((passwordStrength=="middle") ? "bluedark" : "red")}>
                      {passwordStrength}</Typography>
                      </Typography> 
                  </MKBox>
                   ):null} 
                  <MKBox mt={3} >
                    <MKButton
                      disabled={!((passwordStrength=="strong")||(passwordStrength=="middle"))}
                      variant="gradient"
                      color="info"
                      onClick={() => {
                        resetPassword();
                      }}
                      fullWidth
                    >
                      reset
                    </MKButton>
                  </MKBox>
                </MKBox>
              </MKBox>
            )}
          </>
        )}

        {screenComponentIndex == 1 && (
          <>
            <MKBox
              variant="gradient"
              bgColor="info"
              borderRadius="lg"
              coloredShadow="success"
              mx={2}
              mt={-3}
              py={2}
              mb={1}
              textAlign="center"
            >
              <MKTypography variant="h3" fontWeight="medium" color="white" mt={1}>
                Reset Password
              </MKTypography>
              {/* <MKTypography display="block" variant="button" color="white" my={1}>
           Enter your email to reset your password          </MKTypography> */}
            </MKBox>
          </>
        )}

        <MKBox pb={3} px={3}>
          <MKBox component="form" role="form">
            <Divider />
            <MKBox mt={2} mb={1} textAlign="center">
              {loading && (
                <Box sx={{ width: "100%" }}>
                  <CircularProgress />
                </Box>
              )}
{/* 
              {(response ? (response.message ? true : false) : false) && (
                <>
                  <MKTypography
                    variant="body2"
                    color={response.success ? "success" : "warning"}
                    my={1}
                  >
                    {response.message}
                  </MKTypography>
                  <Divider />
                </>
              )} */}
              
            {(response || !isFormValid) && (
              <MKBox mt={1} mb={1} textAlign="center">
                {response && (
                  <Typography
                    variant="body2"
                    color={(response ? response.success : false) ? "green" : "red"}
                  >
                    {" "}
                    <small>{response.message}</small>
                  </Typography>
                )}
                {!isFormValid && (
                  <Typography variant="body2" color={"red"}>
                    <small>{invalidResponse}</small>
                  </Typography>
                )}
              </MKBox>
            )}

              {screenComponentIndex > 1 && !loading && (
                <MKBox mt={2} mb={1} textAlign="center">
                  <Stack spacing={2} direction="row">
                    <MKButton
                      variant="outlined"
                      color="info"
                      size="small"
                      onClick={() => {
                        setResponse(null);
                        setScreenComponentIndex(screenComponentIndex - 1);
                      }}
                    >
                      Back
                    </MKButton>
                  </Stack>
                </MKBox>
              )}

              {/* <MKButton variant="gradient" color="info"   onClick={()=>{searchUser()}} fullWidth>
                login by password
              </MKButton> */}
              <MKBox mt={3} mb={1} textAlign="center">
                <MKTypography variant="button" color="text">
                  Already have an account?{" "}
                  <MKTypography
                    component={Link}
                    to="/login"
                    variant="button"
                    color="info"
                    fontWeight="medium"
                    textGradient
                  >
                    Login
                  </MKTypography>
                </MKTypography>
              </MKBox>
            </MKBox>
          </MKBox>
        </MKBox>
      </Card>
    </CoverLayout>
  );
}

export default Cover;
