 
import { useEffect } from "react";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// react-router components
import { Routes, Route, Navigate, useLocation } from "react-router-dom";

// @mui material components
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";

// Material Kit 2 PRO React themes
import theme from "assets/theme"; 
import routes from "routes"; 
import queryString from "query-string";
 
import customRoutes from "customRoutes"; 
import { CurrentUserProvider } from "Api-contexts/currentUserContext"; 
import SignInBasicPage from "layouts/authentication/sign-in/basic";
import { DataManagementService } from "Api-Services/dataManagementService";

export default function App() {
  const { pathname } = useLocation();

  // Setting page scroll to 0 when changing the route
  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);

     
  useEffect(() => {  
    // console.log("location", location);
    // let baseUrl="https://auth.baally.com/login"
    // let redirectTo=""
    // let searchData="";
    try {
      if (location.search) {
        let locationSearch = queryString.parse(location.search);
        if (locationSearch) {
          
          DataManagementService.setLocalstorageDataSecured(locationSearch, "authSearch");
          //  console.log("authSearch", locationSearch);
          if (locationSearch.redirectTo) {
            let redirectUrl=locationSearch.redirectTo
            DataManagementService.setLocalstorageDataSecured(redirectUrl, "redirectUrl");
          }
          if (locationSearch.authDeepth) {
            let authDeepth=locationSearch.authDeepth
            DataManagementService.setLocalstorageDataSecured(authDeepth, "authDeepth");
          }
          // setRouteSearch(locationSearch);
        }
      } 
    } catch (error) {
      console.log("error", error);
      
    }

    // searchData="?redirectTo='"+location.origin+redirectTo+"'"
    // if (location.hostname) {
    //   if(location.hostname=="localhost"){
    //     baseUrl="http://localhost:5004/login"
    //   }
    // }
    
    // window.location.assign(baseUrl+searchData);
    // window.open(baseUrl+searchData, '_blank');
    // window.location.assign(baseUrl+searchData);
     
  }, [location.pathname, location.search]);
 


  const getRoutes = (allRoutes) =>
    allRoutes.map((route) => {
      if (route.collapse) {
        return getRoutes(route.collapse);
      }

      if (route.route) {
        return <Route exact path={route.route} element={route.component} key={route.key} />;
      }

      return null;
    });

  return (
    <ThemeProvider theme={theme}>
              <ToastContainer />

    <CurrentUserProvider>
      <CssBaseline />
      {/* <NotificationBar/> */}
      {/* <DefaultNavbar
        routes={routes}
        action={{
          type: "external",
          route: "https://www.creative-tim.com/product/material-kit-pro-react",
          label: "Register",
          color: "info",
        }}
        sticky//transparent
        // light
      /> */}
      <Routes>
        {getRoutes(routes)}
        {getRoutes(customRoutes)}
        {/* <Route path="/presentation" element={<Presentation />} /> */}
        <Route path="/" element={<SignInBasicPage/>} />
        <Route path="*" element={<Navigate to="/" />} /> 
      </Routes>
       
      </CurrentUserProvider>
    </ThemeProvider>
  );
}
