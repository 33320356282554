/**
=========================================================
* Material Kit 2 PRO React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import React, { useEffect, useState } from "react";

// react-router-dom components
import { Link, useNavigate } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";
import Switch from "@mui/material/Switch";
import Grid from "@mui/material/Grid";
import MuiLink from "@mui/material/Link";

// @mui icons
import FacebookIcon from "@mui/icons-material/Facebook";
import GitHubIcon from "@mui/icons-material/GitHub";
import GoogleIcon from "@mui/icons-material/Google";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import MKInput from "components/MKInput";
import MKButton from "components/MKButton";
import queryString from "query-string";

// Authentication pages components
// import BasicLayout from "pages/Authentication/components/BasicLayout";

// Images
import bgImage from "assets/images/bg-sign-in-basic.jpeg";
import { Avatar, Box, Button, CardActions, CardContent, CardHeader, CircularProgress, Divider, IconButton, List, ListItem, ListItemAvatar, ListItemButton, ListItemText, Skeleton, Typography } from "@mui/material";
import { HttpService } from "Api-Services/httpService";
import { DataManagementService } from "Api-Services/dataManagementService";
import { useCurrentUser } from "Api-contexts/currentUserContext";
// import List from "assets/theme/components/list";
import { Expand, Favorite, Folder, Login, Person, Share } from "@mui/icons-material";
import CoverLayout from "pages/Authentication/components/CoverLayout";
import { red } from "@mui/material/colors";
import { CommonServices } from "Api-Services/commonServices";
import { UseAuth } from "Api-Services/useAuth";

function BusinessLogin() {
  const [rememberMe, setRememberMe] = useState(false);

  const handleSetRememberMe = () => setRememberMe(!rememberMe);
  const [forceRerender, setForceRerender] = React.useState(true);

  const [currentContextData, updateContext  ] = useCurrentUser();  

  const {currentUser,isUserLoggedIn,myBusinesses}  = currentContextData;  
  //local constants
  const [cardAnimaton, setCardAnimation] = React.useState("cardHidden");
  const [loading, setLoading] = useState(true);
  const [isFormValid, setIsFormValid] = useState(true);
  const [invalidResponse, setInvalidResponse] = useState("");
  const [loginResponse, setLoginResponse] = useState(null);
  const [myProfiles, setMyProfiles] = useState([]);
  

  const navigate = useNavigate();
  
  React.useEffect(() => {
    (async () => {
      getmyProfiles()
    // console.log("myBusinesses",myBusinesses) 
      if(myBusinesses){
        if(myBusinesses.length){ 
          setMyProfiles(myBusinesses)
          // setLoading(false)
        } 
      }
    })() 
  }, [myBusinesses,isUserLoggedIn]);
  
  const getmyProfiles =async ()=>{ 
      setLoading(true)
      let myDetRes=[]
      await getDocumentsAll("mywebsites","/api/profile/myprofiles") .then((myWebsitesRes)=>{
      if(myWebsitesRes.success){
       if(myWebsitesRes.record){ 
       if(myWebsitesRes.record.length){ 
        myDetRes=myWebsitesRes.record
         }
       }
      }
     }
     ) 
     setMyProfiles(myDetRes)
     setLoading(false)
     setForceRerender(!forceRerender)
    //  console.log("myBusinesses myDetRes",myDetRes) 
  }

  useEffect(() => {  
    let id = setTimeout(function () {
      setCardAnimation("");
    }, 700);
    return function cleanup() {
      window.clearTimeout(id);
    };
  }, []);
  
 
  const postDocuments = async (documentName, relativePath, data) => {
    let responseD = { success: false, message: "", record: null };
    let documentsResponse = { success: false };
    setLoading(true);

    ////////// UPDATED/////////////
    documentsResponse = await HttpService.postService(relativePath, data, {}, "");
    // console.log(documentName, " data: ", documentsResponse   );
    setLoading(false);

    if (documentsResponse.success) {
      responseD.success = true;
      responseD.record = documentsResponse.data.record;
      responseD.message = documentsResponse.data.message;
      let record = responseD.record;

      //write codtions down here//

      if (documentName == "login") {
        let path = DataManagementService.getLocalstorageDataSecured("redirectUrl");
        let authSearch = DataManagementService.getLocalstorageDataSecured("authSearch");
        // let path = DataManagementService.getLocalstorageDataSecured("locationSearch");
        // localStorage.clear();
        setLoginResponse(documentsResponse);
        setLoading(true);
        // await getDocumentsAll("allOffices","/api/officeUserRole/myoffices",{})//init user offices and data
        setLoading(false);
        
        let returnToOrigin=true
         
    // console.log("authSearch", authSearch);
        if(authSearch){
          if(authSearch.authDeepth){
            returnToOrigin=false
          if(authSearch.authDeepth=="businessaccount"){
            // path=routeSearch.redirectTo
          }
          }
        }
        if(returnToOrigin){
          window.location.assign(path);
        }
        // window.open(path);
      }
    } else {
      responseD.message = documentsResponse.message;
      if (documentName == "login") {
        setLoginResponse(responseD);
      }
    }

    // console.log(documentName, " responseD: ", responseD   );
    setForceRerender(!forceRerender);
    return responseD;
    ////////// UPDATED/////////////
  };

  const getDocumentsAll = async (documentName, relativePath, additionalData) => {
    let responseD = { success: false, message: "", record: null };
    let documentsResponse = { success: false };
    ////////// UPDATED/////////////
    documentsResponse = await HttpService.getService(
      relativePath,
      additionalData ? additionalData : {},
      ""
    );
    // //console.log(documentName, " data: ", documentsResponse   );

    if (documentsResponse.success) {
      responseD.success = true;
      responseD.record = documentsResponse.data.record;
      responseD.message = documentsResponse.data.message;
      let record = responseD.record;

      //responseD.record //write conditions down here
    }

    return responseD;
  };


 
  const loginToBusiness =async (businessData) => {  
    
      setLoading(true);
    // let addUserToWebsiteRes=await postDocuments("loginToWebsite",relativePath,data)
     let addUserToWebsiteRes=await UseAuth.switchProfileById(businessData.id);   
      //  console.log("switchProfileById", addUserToWebsiteRes);
    // 
     if(addUserToWebsiteRes){
      if(addUserToWebsiteRes.success){ 
          let authSearch = DataManagementService.getLocalstorageDataSecured("authSearch");
          if(authSearch){
          // console.log("businessaccount authSearch", authSearch);
          if(authSearch.authDeepth){
          // returnToOrigin=false
            // if(authSearch.authDeepth=="businessaccount"){
                // console.log("businessaccount", authSearch);
                  if(authSearch.redirectUrl||authSearch.redirectTo  ){ 
                    let urlRedi=authSearch.redirectUrl||authSearch.redirectTo
                    // console.log("redirectUrl", authSearch.redirectUrl);
                    // localStorage.clear(); 
                    // window.location.assign(urlRedi);
                    // window.open(authSearch.redirectUrl );
                    window.open(authSearch.redirectUrl );
                  }
              // }
          }
     } 
    }
     }

    setLoading(false); 
    if(addUserToWebsiteRes.success){  
    }
    else{ 
    }
  }

  return (<>
      {forceRerender}
    <CoverLayout image={bgImage}>
      <Card>
        <MKBox
          variant="gradient"
          bgColor="info"
          borderRadius="lg"
          coloredShadow="info"
          mx={2}
          mt={-3}
          p={0}
          mb={1}
          textAlign="center"
        >
         
         <CardHeader
        avatar={
          <Avatar  >
           <Person/>
          </Avatar>
        }
        action={
          <IconButton aria-label="settings">
            {/* <MoreVertIcon /> */}
          </IconButton>
        }
        title={
          <MKTypography variant="h4" fontWeight="medium" color="white" mt={0}>
          Select Profile
          </MKTypography> }
        // subheader="September 14, 2016"
      /> 
 
        </MKBox>
        <MKBox pt={1} pb={3} px={3}>
          <MKBox  >
         {(!loading)? < >
        
         {(myProfiles?(myProfiles.length?true:false):false)?
          <List dense  sx={{
            // width: '100%',
            // width:460,
            // maxWidth: 360,
            bgcolor: 'background.paper',
            position: 'relative',
            overflow: 'auto',
            maxHeight: 700,
            // '& ul': { padding: 0 },
          }}
          // subheader={<li />}
           >
              {myProfiles.map((myBusiness)=>{
                let businessData=myBusiness
                if(businessData){
                  return<>
                  
                  <Divider variant="inset" component="li" />
                  <ListItemButton 
                            onClick={(e)=>{
                              loginToBusiness(businessData)
                              // console.log("USER",websiteD)
                              // setSelectedUser(websiteD);
                            }}
                            sx={{ cursor: "pointer" }}
                                   
                      secondaryAction={
                        <IconButton edge="end" aria-label="delete">
                          <Login />
                        </IconButton>
                      }
                    >
                      <ListItemAvatar>
                        <Avatar src={businessData.profileImageUrl||""}>
                          {(!(businessData.profileImageUrl))&&<Person />}
                        </Avatar>
                      </ListItemAvatar>
                      <ListItemText
                        primary={CommonServices.getProfileName(businessData)||""}
                        // secondary={secondary ? 'Secondary text' : null}
                      />
                    </ListItemButton></> 
                    }}
               )}
            </List>:
            <>
              <Box  >
                <Skeleton />
                <Skeleton animation="wave" />
                <Skeleton animation={false} />
              </Box>
            </>
      } 
          </>
      :<>

      <Box sx={{ display: 'flex', alignItems: 'center' }}>

      <Box sx={{ margin: 1 }}> 
                  <Skeleton variant="circular">
                    <Avatar />
                  </Skeleton> 
              </Box>
              <Box sx={{ width: '100%' }}> 
                  <Skeleton width="100%">
                    <Typography>.</Typography>
                  </Skeleton> 
              </Box>
              </Box>
              <>
                    <Box  >
                      <Skeleton />
                      <Skeleton animation="wave" />
                      <Skeleton animation={false} />
                    </Box>
                  </>

      </>

}  
            {(loginResponse || !isFormValid) && (
              <MKBox mt={3} mb={1} textAlign="center">
                {loginResponse && <small>{loginResponse.message}</small>}
                {!isFormValid && <small>{invalidResponse}</small>}
              </MKBox>
            )}
            {loading && (
              <MKBox mt={3} mb={1} textAlign="center">
                <CircularProgress size={24} />{" "}
              </MKBox>
            )}
            
            <MKBox mb={1} textAlign="center">
              <MKTypography variant="button" color="text">
             {(myProfiles?(myProfiles.length?false:true):true)&& <span> Don&apos;t have a business account?{" "}</span>}
                <MKTypography
                  component={Link}
                  to="https://portal.baliwebsites.bfamily.et/admin/create-new-website"
                  variant="button"
                  color="info"
                  fontWeight="medium"
                  textGradient
                >
                  Create New Personal Profile
                </MKTypography>
              </MKTypography>
            </MKBox>
          </MKBox>
        </MKBox>
      </Card>
    </CoverLayout>
  </>);
}

export default BusinessLogin;
