/**
=========================================================
* Material Kit 2 PRO React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Card from "@mui/material/Card";
// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import MKInput from "components/MKInput";
import MKButton from "components/MKButton";
// Authentication layout components
import CoverLayout from "pages/Authentication/components/CoverLayout";

import queryString from "query-string";
// Images
import bgImage from "assets/images/bg-reset-cover.jpeg";
import { HttpService } from "Api-Services/httpService";
import React, { useEffect, useState } from "react";
import { Avatar, Box, CircularProgress, Divider, FormControl, Grid, Icon, IconButton, InputAdornment, InputLabel, LinearProgress, List, ListItem, ListItemAvatar, ListItemButton, ListItemText, OutlinedInput, Stack, TextField, Typography } from "@mui/material";
import { Delete, Folder, Person, Send, Visibility, VisibilityOff } from "@mui/icons-material";
import { Link } from "react-router-dom";

function ForgetPassword() {
  const [forceRerender ,setForceRerender ] =  useState(false);
  const [screenComponentIndex ,setScreenComponentIndex ] =  useState(1);
 
  const [resetSuccess ,setResetSuccess ] =  useState(false);
  const [response, setResponse] = useState(null);
  const [verificationResponse, setVerificationResponse] = useState(null);
  //local constants 
  const [usersFound, setUsersFound] = useState(null);
  const [loading, setLoading] = useState(false);
  const [companyName, setCompanyName] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [userCardId, setUserCardId] = useState('');
  const [isFormValid, setIsFormValid] = useState(false);
  const [loginResponse, setLoginResponse] = useState(null);
  // const history=useHistory()


  // useEffect(() => { 
  // }, [location.pathname, location.search]);


  const postDocuments = async(documentName,relativePath,data  )=>{   
    let responseD={success:false,message:"",record: null};
    let documentsResponse={success:false}   
   
    ////////// UPDATED///////////// 
    documentsResponse= await HttpService.postService(relativePath,data,{},""); 
    // console.log(documentName, " data: ", documentsResponse   );   
  
    if(documentsResponse.data)  {
      responseD.record=documentsResponse.data.record; 
      responseD.message=documentsResponse.data.message; 
    }
    if(documentsResponse.success){  
      responseD.success=true;    
      }  
      // console.log(documentName, " responseD: ", responseD   );   
      setForceRerender(!forceRerender);
      return responseD;
    ////////// UPDATED///////////// 
  }
   

const resetPassword = async () => {
    setResponse(null)
    setResetSuccess(false)
try {
  let validD=true  
  if(!(newPassword==confirmPassword)){
    // setIsFormValid(true);
    validD=false
  }
 setLoading(true)
  // console.log("resetPassword",(newPassword==confirmPassword),newPassword,confirmPassword,validD, verificationResponse.hashedResetToken)

  if(validD&&verificationResponse.hashedResetToken){
   let data={
    "hashedResetToken": verificationResponse.hashedResetToken,
    // "platformUserName": platformUserName,
    "password": newPassword,
    // "confirmPassword": confirmPassword
    } 
    let relativePath="/api/authorization/resetuserpassword";
   let resetPasswordRes= await postDocuments("resetPassword",relativePath,data,{})
   setResponse(resetPasswordRes)
   if(resetPasswordRes.success){
    setResetSuccess(resetPasswordRes.success)
   }
   setLoading(false)
  } 
} catch (error) {
  
 setLoading(false)
  // console.log(error,"ererer")
}
} 


  const searchUser = async () => {
    try {
      setResponse(null)
    // console.log("login")
    // console.log("forgort",userCardId)
    let validD=false 
    setUsersFound(null)
    if(userCardId){
      setIsFormValid(true);
      validD=true
    }
    else{
      setIsFormValid(false)
      validD=false
    }

    if(validD){
     let data={
        "userCardIdOrUserEmail":userCardId  
      } 
      setLoading(true)
       let relativePath="/api/authorization/getUserFromPlatform";
       let searchUserRes= await postDocuments("searchUser",relativePath,data,{})
      setLoading(false) 
      
      // console.log("searchUserRes",searchUserRes)
        setResponse(searchUserRes)
      if(searchUserRes.success){
        setUsersFound(searchUserRes)
        setScreenComponentIndex(2)
      }
      else{
        if(!(searchUserRes.message)){ 
         setResponse({message :   "No user found, try again",
          record: null,
          success :  false})}
         setUsersFound(null)
      }
      setForceRerender(!forceRerender)} 
    } catch (error) {
      
    }
  } 
  
  const sendVerification = async (user) => { 
    try {
      
      setResponse(null)

    if(user.id){  
     let data={
        "platformUserName":companyName,
        "id":user.id 
      }  
      let relativePath="/api/authorization/sendforgotPasswordVerification";
      let sendVerificationRes= await postDocuments("sendVerification",relativePath,data,{})
      setResponse(sendVerificationRes)
      setScreenComponentIndex(3)
      setForceRerender(!forceRerender) 
    } 

    } catch (error) {
      
    }
  } 


  const hidePartialCharacters=(wordsData)=>{ 
    try {
      
    let finalString=""
    let emailDomain=""
    if(wordsData){
      if(wordsData.length){
  
    let wordsD=wordsData.toString()
    if((wordsD.indexOf("@")>-1)&&(wordsD.indexOf(".")>-1)){
    let indexofChar=wordsD.indexOf("@");
    emailDomain=wordsD.substr(indexofChar,wordsD.length)
    wordsD=wordsD.substr(0, indexofChar)
    }
  
    let words = wordsD.split(' ');
    // console.log(words[3]);
    words.forEach(stringD => { 
      let hideLength=parseInt(stringD.length/3)
      if(hideLength%2!=0){
        hideLength=hideLength+1
      }
      // parseInt() Method
      let astrixChar=""
      let selectedChar=stringD.substr(0, hideLength)
        for(let s=0;s<(stringD.length-hideLength);s++){ 
          astrixChar=astrixChar+"*"
        }
        let newString=selectedChar+astrixChar
        finalString=finalString+" "+newString
      });
     }
    }
      return finalString+""+emailDomain
    } catch (error) {
      
    }
    }
    
   
  return (
    <CoverLayout coverHeight="50vh" image={bgImage}>
       <Card>
        
      {(screenComponentIndex==1)&& 
      <>
        <MKBox
          variant="gradient"
          bgColor="info"
          borderRadius="lg"
          coloredShadow="success"
          mx={2}
          mt={-3}
          py={2}
          mb={1}
          textAlign="center"
        >
          <MKTypography variant="h3" fontWeight="medium" color="white" mt={1}>
            Forgot Password
          </MKTypography>
          <MKTypography display="block" variant="button" color="white" my={1}>
           Enter your email to reset your password          </MKTypography>
        </MKBox>
        <MKBox pt={4}  px={3}>
          <MKBox component="form" role="form">
            <MKBox mb={4}>
              <MKInput
                type="email"
                label="Email"
                id="email"
                // variant="standard"
                fullWidth
                InputLabelProps={{ shrink: true }}
                placeholder="john@email.com"
                
                onChange={(e)=>{  setUserCardId(e.target.value)} }
              />
            </MKBox>
            <MKBox mt={6} mb={1}>
              <MKButton variant="gradient" color="info"   onClick={()=>{searchUser()}} fullWidth>
                Submit
              </MKButton>
            </MKBox>
             
          </MKBox>
        </MKBox>
      </>
       }
       
      {((screenComponentIndex==2)&&usersFound)&&
      <>
        <MKBox
          variant="gradient"
          bgColor="info"
          borderRadius="lg"
          coloredShadow="success"
          mx={2}
          mt={-3}
          py={2}
          mb={1}
          textAlign="center"
        >
          <MKTypography variant="h3" fontWeight="medium" color="white" mt={1}>
          Accounts
          </MKTypography>
          <MKTypography display="block" variant="button" color="white" my={1}>
            select your account from below
          </MKTypography>
        </MKBox>
        <MKBox pt={4}  px={3}>
          <MKBox component="form" role="form">
            <MKBox 
          textAlign="center">
            <Grid item sm={12} md={8}>
          {/* <Typography sx={{ mt: 4, mb: 2 }} variant="h6" component="div">
            Avatar with text and icon
          </Typography> */}
          {/* <Demo> */}
             {(usersFound.success)&& 
            <List  fullWidth
            // dense={dense}
            > 
             { usersFound.record.users.map((user)=> 
      
                <ListItem
                  secondaryAction={
                    <IconButton edge="end" aria-label="delete"   onClick={(e)=>{ 
                      // console.log("USER sendVerification","user")
                      sendVerification(user);
                    }}>
                      <Send fontSize="large" color="success"/>
                    </IconButton>
                  }
                >
                  <ListItemButton   onClick={(e)=>{ 
                      // console.log("USER sendVerification","user")
                      sendVerification(user);
                    }}>
                  <ListItemAvatar>
                   {user.userFrontImage?<Avatar src={user.userFrontImage}/>: <Avatar>
                      <Person />
                    </Avatar>}
                  </ListItemAvatar>
                  <ListItemText
                    primary={user.userName} 
                    secondary={hidePartialCharacters(user.userEmail)}
                  /> </ListItemButton>
                </ListItem> )}
            </List>}
          {/* </Demo> */}
        </Grid>
            </MKBox>
            {/* <MKBox mt={6} mb={1}>
              <MKButton variant="gradient" color="info"   onClick={()=>{searchUser()}} fullWidth>
                reset
              </MKButton>
            </MKBox> */}
 
          </MKBox>
        </MKBox>
      </>
      }
      
      {((screenComponentIndex==3)&&usersFound)&&
      <>
        <MKBox
          variant="gradient"
          bgColor="info"
          borderRadius="lg"
          coloredShadow="success"
          mx={2}
          mt={-3}
          py={2}
          mb={1}
          textAlign="center"
        >
          <MKTypography variant="h3" fontWeight="medium" color="white" mt={1}>
          Verification
          </MKTypography>
          
        </MKBox>
        <MKBox pt={4}   px={3}>
          <MKBox component="form" role="form">
            <MKBox mb={2}  textAlign="center">
            {/* <Grid item xs={12} md={12}> 
          <MKTypography    variant="button" color="dark" my={1}>
          Verification email sent, check your email. You will receive an e-mail in maximum 60 seconds
          </MKTypography>
        
        </Grid> */}
            </MKBox> 
          </MKBox>
        </MKBox>
      </>
      }
      
      <MKBox  pb={3} px={3}>
          <MKBox component="form" role="form"> 
          <Divider/>
            <MKBox mt={2} mb={1}  textAlign="center">
              
             {loading&&<Box sx={{ width: '100%' }}>
                 <CircularProgress />
              </Box>}
           
          {(response?(response.message?true:false):false)&&<>
           <MKTypography    variant="body2"  color={response.success?"success":"warning"} my={1}>
            {response.message}
          </MKTypography>
          <Divider/>
          </>}

          {((screenComponentIndex>1)&&(!loading)) && <MKBox mt={2} mb={1}  textAlign="center">
            <Stack spacing={2} direction="row">
              <MKButton variant="outlined" color="info" size="small"
                onClick={()=>{
                  setResponse(null)
                  setScreenComponentIndex(screenComponentIndex-1)}}  >
                Back
              </MKButton>
            </Stack>
            </MKBox>}


              {/* <MKButton variant="gradient" color="info"   onClick={()=>{searchUser()}} fullWidth>
                login by password
              </MKButton> */}
              <MKBox mt={3} mb={1} textAlign="center">
              <MKTypography variant="button" color="text">
                Already have an account?{" "}
                <MKTypography
                  component={Link}
                  to="/login"
                  variant="button"
                  color="info"
                  fontWeight="medium"
                  textGradient
                >
                  Login
                </MKTypography> 
              </MKTypography> 
            </MKBox>
            </MKBox>
          </MKBox>
        </MKBox>

      </Card>
    </CoverLayout>
  );
}

export default ForgetPassword;
